
.swiper-pagination-bullet-active {
    background-color: #ed3237;
}

.swiper-button-prev {
    color: #718096;
}

.swiper-button-next {
    color: #718096;
}